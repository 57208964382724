@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  touch-action: pan-y;
}

html {
  background: #bf3131;
}

.highlight {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=bf3131);
  margin: -2px -6px;
  padding:  6px  8px;
}

*{touch-action: pan-x pan-y} /*only allow scroll gestures*/

body video {
  max-width: initial;
}

#scene-wrapper > video {
  display: none;
}

body > canvas {
  width: 100%;
}